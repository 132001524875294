import { useState } from 'react'

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Box, Typography } from '@mui/material';


const InspectionCreation = ({ inputState, handleInputChange, handleClose, handleBack, handleSubmit }) => {
  const canSubmit = () => {
    return inputState.date !== undefined && inputState.date !== "" &&
      inputState.client_name !== undefined && inputState.client_name !== "" &&
      inputState.machine_number !== undefined && inputState.machine_number !== "" &&
      inputState.machine_model !== undefined && inputState.machine_model !== "" &&
      inputState.service_engineer !== undefined && inputState.service_engineer !== ""
  }


  return <div>
    <DialogTitle>Create New Inspection Project</DialogTitle>
    <DialogContent>
      <DialogContentText color="text.primary" sx={{ mb: 2 }}>
        We need some additional information in order to create a new project.
      </DialogContentText>

      <InputField
        required
        value={inputState.title}
        title=""
        name="date"
        type="date"
        handleInputChange={handleInputChange}
      />

      <InputField
        required
        value={inputState.description}
        title="Client Name"
        name="client_name"
        handleInputChange={handleInputChange}
      />

      <InputField
        required
        value={inputState.project_number}
        title="Machine Number"
        name="machine_number"
        handleInputChange={handleInputChange}
      />

      <InputField
        required
        value={inputState.client_reference}
        title="Model"
        name="machine_model"
        handleInputChange={handleInputChange}
      />

      <InputField
        required
        value={inputState.scope}
        title="Service Engineer"
        name="service_engineer"
        handleInputChange={handleInputChange}
      />

    </DialogContent>
    <DialogActions>
      <Button onClick={handleClose}>Cancel</Button>
      <Button onClick={handleBack}>Back</Button>
      <Button disabled={!canSubmit()} onClick={handleSubmit}>Submit</Button>
    </DialogActions>
  </div>
}

const HealthCheckCreation = ({ inputState, handleInputChange, handleClose, handleBack, handleSubmit }) => {
  const canSubmit = () => {
    return inputState.date !== undefined && inputState.date !== "" &&
      inputState.client_name !== undefined && inputState.client_name !== "" &&
      inputState.machine_number !== undefined && inputState.machine_number !== "" &&
      inputState.machine_model !== undefined && inputState.machine_model !== "" &&
      inputState.service_engineer !== undefined && inputState.service_engineer !== ""
  }

  return <div>
    <DialogTitle>Create New Health Check Project</DialogTitle>
    <DialogContent>
      <DialogContentText color="text.primary" sx={{ mb: 2 }}>
        We need some additional information in order to create a new project.
      </DialogContentText>

      <InputField
        required
        value={inputState.title}
        title=""
        name="date"
        type="date"
        handleInputChange={handleInputChange}
      />

      <InputField
        required
        value={inputState.description}
        title="Client Name"
        name="client_name"
        handleInputChange={handleInputChange}
      />

      <InputField
        required
        value={inputState.project_number}
        title="Machine Number"
        name="machine_number"
        handleInputChange={handleInputChange}
      />

      <InputField
        required
        value={inputState.client_reference}
        title="Model"
        name="machine_model"
        handleInputChange={handleInputChange}
      />

      <InputField
        required
        value={inputState.scope}
        title="Service Engineer"
        name="service_engineer"
        handleInputChange={handleInputChange}
      />

    </DialogContent>
    <DialogActions>
      <Button onClick={handleClose}>Cancel</Button>
      <Button onClick={handleBack}>Back</Button>
      <Button disabled={!canSubmit()} onClick={handleSubmit}>Submit</Button>
    </DialogActions>
  </div>
}


const InputField = ({ title, name, value, required, handleInputChange, type = "text" }) =>
  <TextField
    sx={{ mb: 1 }}
    required={required}
    margin="dense"
    id={name}
    label={title}
    type={type}
    fullWidth
    variant="standard"
    value={value}
    onChange={(e) => handleInputChange(name, e)}
  />


const ProjectCreateDialog = ({ open, onClose }) => {
  const [inputState, setInputState] = useState({
    date: "",
    client_name: "",
    machine_number: "",
    machine_model: "",
    service_engineer: "",
  });




  const handleClose = () => {
    onClose(undefined)
    setInputState({})
  }

  const handleBack = () => {
    setInputState({})
  }

  const handleSubmit = () => {
    onClose(inputState)
    setInputState({})
  }

  const handleInputChange = (input, event) => {
    setInputState((prevState) => ({ ...prevState, ...{ [input]: event.target.value } }))
  }

  const startInspectionProject = () => {
    setInputState((prevState) => ({ ...prevState, ...{ "inspectionProject": true } }))
  }

  const startHealthCheckProject = () => {
    setInputState((prevState) => ({ ...prevState, ...{ "healthCheckProject": true } }))
  }

  const ProjectCreateButton = ({ text, onClick }) => {
    return <Button
      variant="outlined"
      onClick={onClick} >{text}</Button>
  }

  const ProjectSelectScreen = () => {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", gap: 1.5, p: 4, pt: 3 }}>
        <Typography sx={{ mb: 3 }}><b>Create Project</b></Typography>
        <ProjectCreateButton text="Create Inspection Project" onClick={() => { startInspectionProject() }} />
        or
        <ProjectCreateButton text="Create Health Check Project" onClick={() => { startHealthCheckProject() }} />
      </Box>
    )
  }



  return (
    <Dialog open={open} onClose={handleClose} PaperProps={{ elevation: 0, variant: "outlined" }} >
      {inputState?.healthCheckProject !== true && inputState?.inspectionProject !== true && <ProjectSelectScreen />}

      {inputState?.healthCheckProject && <HealthCheckCreation handleInputChange={handleInputChange} handleSubmit={handleSubmit} handleClose={handleClose} inputState={inputState} handleBack={handleBack} />}
      {inputState?.inspectionProject && <InspectionCreation handleInputChange={handleInputChange} handleSubmit={handleSubmit} handleClose={handleClose} inputState={inputState} handleBack={handleBack} />}
    </Dialog>
  )
}

export default ProjectCreateDialog;