import { useState } from 'react'

import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import AssignmentIcon from '@mui/icons-material/Assignment';
import ListAltIcon from '@mui/icons-material/ListAlt';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import ModeEditIcon from '@mui/icons-material/ModeEdit';

import { TextField, Tooltip } from '@mui/material';

import { updateChecklistInfo, deleteChecklist } from '../../util/api/checklist';

export default function ChecklistOverviewCard({ id, title, description, lastUpdate, onDelete }) {

  const [editable, setEditable] = useState(false)
  const [editableTitle, setEditableTitle] = useState(title)
  const [editableDescription, setEditableDescription] = useState(description)

  const handleSave = () => {
    updateChecklistInfo(id, editableTitle, editableDescription).then(() => {
      setEditable(false)
    })
  }

  const handleEnableEdit = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setEditable(true)
  }

  const EditButton = () => {
    return (
      <Tooltip title="Edit Checklist Info">
        <IconButton aria-label="edit" onClick={handleEnableEdit} >
          <ModeEditIcon />
        </IconButton>
      </Tooltip >
    )
  }

  const SaveButton = () => {
    return (
      <Tooltip title="Save Checklist Info">
        <IconButton aria-label="save" onClick={handleSave}>
          <SaveIcon />
        </IconButton>
      </Tooltip>
    )
  }

  return (
    <Card sx={{ width: 345, borderRadius: 0.5, boxShadow: "none" }}>
      <CardHeader
        avatar={
          <Avatar sx={{ bgcolor: "#83D100" }} aria-label="checklist">
            <ListAltIcon />
          </Avatar>

        }
        action={
          editable ? <SaveButton /> : <EditButton />
        }
        title={!editable ? editableTitle : <TextField variant="standard" value={editableTitle} onChange={(e) => setEditableTitle(e.target.value)} />}
        subheader={"Last update: " + lastUpdate}
        subheaderTypographyProps={{
          color: "#dddddd",
          variant: "body2",
          fontSize: "12px"
        }}
      />
      <CardContent sx={{ maxWidth: "100%" }}>
        <Typography variant="body2" color="text.primary" >
          {!editable ? editableDescription : <TextField sx={{ width: "100%" }} variant="standard" multiline value={editableDescription} onChange={(e) => setEditableDescription(e.target.value)} />}
        </Typography>
      </CardContent>
      <CardActions disableSpacing>
        {/* <IconButton aria-label="favorite">
          <FavoriteIcon />
        </IconButton>
        <IconButton aria-label="share">
          <ShareIcon />
        </IconButton> */}
        <IconButton aria-label="edit" onClick={() => {
          window.location.href = `/checklist/editor/${id}`;
        }}>
          <AssignmentIcon />
        </IconButton>
        <IconButton aria-label="delete" onClick={() => {
          deleteChecklist(id).then(() => {
            onDelete();
          })
        }}>
          <DeleteIcon />
        </IconButton>
      </CardActions>
    </Card>
  )
}
