import {
  Box,
  List,
  ListItem,
  Link,
  Typography,
  Skeleton,
  Card,
  CardHeader,
  CardContent,
  Chip,
  Button,
  Tooltip,
  Badge,
  Divider,
} from "@mui/material";
import CheckIcon from '@mui/icons-material/Check';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

import ChecklistInput from "./ChecklistInput/ChecklistInput";

import { getChecklistStep } from "../../util/api/checklist";
import { useEffect, useState } from "react";
import { CardActions } from "@material-ui/core";
import StepRejectionPanel from "./StepRejectionPanel";
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import SyncProblemIcon from '@mui/icons-material/SyncProblem';
import PendingIcon from '@mui/icons-material/Pending';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import StepApprovalPopup from "../Approval/StepApprovalPopup";
import ChecklistFiles from "./ChecklistFiles";
import { AntTabs, AntTab } from "./AntTab";
import ChecklistComments from "./ChecklistComments";

const ACTION_TABLE = {
  "A": "Approve",
  "M": "Monitor",
  "R": "Review",
  "H": "Hold",
  "W": "Witness",
  None: ""
}

const ChecklistStep = ({ checklistID, stepID, onUpdate, disabled, onSignLocally }) => {

  const [step, setStep] = useState(undefined);
  const [loading, setLoading] = useState(true);
  const [rejectionPanelOpen, setRejectionPanelopen] = useState(false)
  const [stepApprovalPopupOpen, setStepApprovalPopupOpen] = useState(false)
  const [tabValue, setTabValue] = useState(0)

  const fetchStep = () => {
    return getChecklistStep(checklistID, stepID).then((response) => {
      setStep(response.data)
      setLoading(false)
      setStepApprovalPopupOpen(false);
      return response
    })
  }

  useEffect(() => {
    if (loading) {
      fetchStep()
    }
  }, [loading])

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const ActionLabel = () => {

    if (step?.punchlist_step?.completed) {
      return <Chip
        color="success"
        label={
          <Box sx={{
            padding: 1,
            justifyContent: "center",
            alignItems: "center",
            display: "flex"
          }}>
            <Typography>Revision Done</Typography>
            <PublishedWithChangesIcon sx={{ ml: 1 }} />
          </Box>
        }
      />
    }

    if (step?.punchlist_step) {
      return <Chip
        color="warning"
        label={
          <Box sx={{
            padding: 1,
            justifyContent: "center",
            alignItems: "center",
            display: "flex"
          }}>
            <Typography>Revision Requested</Typography>
            <SyncProblemIcon sx={{ ml: 1 }} />
          </Box>
        }
      />
    }

    if (step?.completed && (step?.external_action === "H" || step?.external_action === "W") && step?.approval_session && (step?.approved_at === null && step.waived_at === null)) {
      return (
        <Tooltip title={`Awaiting approval from ${step?.approval_session?.approver_email}`} >
          <Chip
            color="warning"
            label={
              <Box sx={{
                padding: 1,
                justifyContent: "center",
                alignItems: "center",
                display: "flex"
              }}>
                <Typography>Approval Pending</Typography>
                <PendingActionsIcon sx={{ ml: 1 }} />
              </Box>
            }
          />
        </Tooltip>
      )
    }

    if (
      step?.completed
      && (step?.external_action === "H" || step?.external_action === "W")
      && step?.approval_session === null
      && step?.approved_at === null
    ) {
      return (
        <Tooltip title="Click here to invite an approver">
          <Chip
            color="error"
            clickable
            onClick={() => setStepApprovalPopupOpen(true)}
            label={
              <Box sx={{
                padding: 1,
                justifyContent: "center",
                alignItems: "center",
                display: "flex"
              }}>
                <Typography>Approval Needed</Typography>
                <ErrorOutlineIcon sx={{ ml: 1 }} />
              </Box>
            }
          />
        </Tooltip>
      )
    }


    if (step?.completed && step?.approval_session && step?.approved_at !== null) {
      return <Chip
        color="success"
        label={
          <Box sx={{
            padding: 1,
            justifyContent: "center",
            alignItems: "center",
            display: "flex"
          }}>
            <Typography>Approved</Typography>
            <CheckIcon sx={{ ml: 1 }} />
          </Box>
        }
      />
    }

    if (step?.completed && step?.approval_session && step?.waived_at !== null) {
      return <Chip
        color="success"
        label={
          <Box sx={{
            padding: 1,
            justifyContent: "center",
            alignItems: "center",
            display: "flex"
          }}>
            <Typography>Waived</Typography>
            <CheckIcon sx={{ ml: 1 }} />
          </Box>
        }
      />
    }


    if (step?.completed) {
      return <Chip
        color="success"
        label={
          <Box sx={{
            padding: 1,
            justifyContent: "center",
            alignItems: "center",
            display: "flex"
          }}>
            <Typography>Done</Typography>
            <CheckIcon sx={{ ml: 1 }} />
          </Box>
        }
      />
    }


    return <div></div>
  }

  const HeaderText = () => {
    if (step?.completed && step?.approval_session && step?.approved_at !== null) {
      return <i>Approved by {step?.approval_session?.approver_email} at {new Date(Date.parse(step?.approved_at)).toDateString()}, {new Date(Date.parse(step?.approved_at)).toLocaleTimeString()} </i>
    }

    if (step?.completed && step?.approval_session && step?.waived_at !== null) {
      return <i>Waived by {step?.approval_session?.approver_email} at {new Date(Date.parse(step?.waived_at)).toDateString()}, {new Date(Date.parse(step?.waived_at)).toLocaleTimeString()} </i>
    }

    if (step?.approval_session) {
      return <i>Awaiting approval from {step?.approval_session?.approver_email}</i>
    }

    return <div></div>
  }

  return (
    <Card sx={{
      mt: 0,
      width: "100%",
      borderRadius: 0,
      boxShadow: "none",
      display: "flex",
      flexDirection: "column",
      borderBottom: "1px solid",
      borderColor: "#212529",
      borderRadius: 1,
      p: 1,
      overflow: "scroll"
    }}>
      <StepRejectionPanel open={rejectionPanelOpen} onClose={() => { setRejectionPanelopen(false); setLoading(true) }} checklistID={checklistID} stepID={stepID} />
      <StepApprovalPopup onSignLocally={onSignLocally} open={stepApprovalPopupOpen} onClose={() => { fetchStep(); }} stepID={stepID} />
      {loading ? <Skeleton variant="rounded" width="100%" height={120} /> : <div>
        <CardHeader
          title={step?.title}
          subheader={
            <Box sx={{
              display: "flex",
              flexDirection: "column",

            }}>
              <Box display={"flex"} sx={{ color: "rgba(255, 255, 255, 0.6)" }}>
                <div style={{ marginRight: 100 }}><b>Internal:</b> <i>{ACTION_TABLE[step?.action]}</i></div>
                <div><b>External:</b> <i>{ACTION_TABLE[step?.external_action]}</i></div>
              </Box>
              < HeaderText />
            </Box>
          }

          action={<ActionLabel />}
        />

        <CardContent sx={{
          mt: 0,
          pt: 0,
          pb: 0
        }}>
          <Divider sx={{ mb: 1, mt: 1 }} />
          <Typography variant="body1" sx={{ whiteSpace: "normal" }}>
            {step?.description}
          </Typography>

        </CardContent>
        <CardContent sx={{
          pb: { xs: 0, md: 0, },
          pt: { xs: 0, md: 0 },
          pl: 3
        }}>
          <List>
            {step?.inputs?.map((input) => {
              return (
                <ChecklistInput
                  input={input}
                  checklistID={step?.parent_id}
                  stepID={step?.id}
                  onUpdate={() => {
                    fetchStep().then((response) => {
                      console.log("DATA", response?.data)
                      if (response?.data?.completed && (response?.data?.external_action == "H" || response?.data?.external_action == "W")) {
                        setStepApprovalPopupOpen(true)
                      }
                    })
                    onUpdate();
                  }}
                  disabled={disabled || step?.disabled}
                />
              )
            })}
          </List>
          <CardActions

          >

            {disabled || step?.disabled || step?.completed ||
              <Box sx={{ ml: "auto", mr: 0 }}>
                <Tooltip title={"Add the step to the punchlist"}>
                  <Button
                    variant="contained"
                    color="error"
                    onClick={() => setRejectionPanelopen(true)}
                  >
                    Reject Step
                  </Button>
                </Tooltip>
              </Box>
            }
          </CardActions>

        </CardContent>
        <CardContent sx={{
          background: "#283036",
          borderRadius: 1,
          p: { xs: 0, md: 0 }
        }}>
          <AntTabs value={tabValue} onChange={handleTabChange} aria-label="View Select">
            <AntTab label="Files" count={step?.files?.length} />
            <AntTab label="Comments" count={step?.comments?.length} />
          </AntTabs>
          {tabValue == 0 && <ChecklistFiles step={step} />}
          {tabValue == 1 && <ChecklistComments step={step} disabled={step?.disabled} />}

        </CardContent>

      </div>}
    </Card >
  )
}

export default ChecklistStep;