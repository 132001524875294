import { useState, useEffect } from 'react'
import { useSearchParams, useParams } from "react-router-dom";
import { useDispatch } from 'react-redux';

import { Grid, Box, } from "@material-ui/core";

import PDFViewer from "../../../components/Approval/PDFViewer";
import RejectionPopup from '../../../components/Approval/RejectionPopup';

import { getProjectApprovalSession, approveProjectSession, postProjectIssue } from '../../../util/api/projectApproval';

import { setError } from '../../../actions/notificationActions';
import {
  Button,
  Divider,
  Paper,
} from '@mui/material';

import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';

import Done from '../../../components/Approval/Done';
import Loading from '../../../components/Loading';
import ProjectSignDialog from './ProjectApprovalSignDialog';

const ProjectApproval = () => {
  const dispatch = useDispatch()
  const { sessionID } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const [session, setSession] = useState(null)
  const [rejectDialogOpen, setRejectDialogOpen] = useState(false);
  const [loading, setLoading] = useState(true)
  const [signatureOpen, setSignatureOpen] = useState(false);

  const fetchSession = () => {
    getProjectApprovalSession(sessionID, searchParams.get("key")).then((res) => {
      setSession(res.data)
      setLoading(false)
    }).catch((e) => {
      dispatch(setError(e.response?.data?.detail))
    })
  }

  useEffect(() => {
    if (sessionID !== null) {
      fetchSession();
    }
  }, [sessionID])

  const handleApprove = () => {
    // approveProjectSession(sessionID, searchParams.get("key")).then(() => {
    //   fetchSession();
    // })
    setSignatureOpen(true);
  }

  const handleReject = () => {
    setRejectDialogOpen(true)
  }

  const onRejectSubmit = (value) => {
    postProjectIssue(sessionID, searchParams.get("key"), value).then(() => {
      fetchSession();
    })
  }

  const handleRejectDialogClose = () => {
    setRejectDialogOpen(false);
  }

  const ActionButtons = () => {
    return (
      <Box sx={{
        position: "absolute",
        bottom: 50,
        zIndex: 1,
        width: "100vw"
      }}>
        <Grid container>
          <Grid item xs={1} md={4} lg={5}>

          </Grid>
          <Grid item xs={10} md={4} lg={2}>
            <Paper sx={{ padding: "10px", display: "flex", justifyContent: "center", }}>
              <Button
                onClick={handleReject}
                startIcon={<ThumbDownIcon />}
                variant="outlined"
                sx={{ width: "120px" }}
              >
                Reject
              </Button>
              <Divider horizontal sx={{ ml: 4, mr: 4, height: "10px" }} />
              <Button
                sx={{
                  width: "120px"
                }}
                onClick={handleApprove}
                endIcon={<ThumbUpIcon />}
                variant="outlined"
              >
                Approve
              </Button>
            </Paper>
          </Grid>

          <Grid item xs={5}>

          </Grid>
        </Grid>
      </Box>
    )

  }

  const open = (session?.session?.status !== "DONE" && session?.issues?.length === 0)

  return (
    loading ? <Loading /> :
      <div>

        <RejectionPopup
          open={rejectDialogOpen}
          onClose={handleRejectDialogClose}
          onSubmit={onRejectSubmit}
        />

        <ProjectSignDialog
          onClose={() => { setSignatureOpen(false) }}
          open={signatureOpen}
          title={session?.session?.title}
          onSignOff={() => { setSignatureOpen(false); fetchSession(); }}
          signKey={searchParams.get("key")}
          sessionID={sessionID}
        />

        {open && <ActionButtons />}

        <Grid>
          {!open && <Done />}
          {open && <PDFViewer
            title={session?.session?.title + " Test Plan"}
            filename={`/api/approval/project/mrb/${searchParams.get("key")}`} />}
        </Grid>
      </div>
  )
}

export default ProjectApproval;