import { useState, useEffect } from 'react'
import { useSearchParams, useParams } from "react-router-dom";
import { useDispatch } from 'react-redux';

import { Grid, Box, } from "@material-ui/core";

import PDFViewer from "../../../components/Approval/PDFViewer";
import RejectionPopup from '../../../components/Approval/RejectionPopup';

import { getChecklistApprovalSession, approveSession, postIssue } from '../../../util/api/checklistApproval';

import { setError } from '../../../actions/notificationActions';
import {
  Button,
  Divider,
  Paper,
} from '@mui/material';

import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';

import Done from '../../../components/Approval/Done';
import Loading from '../../../components/Loading';
import ChecklistSignDialog from './ChecklistApprovalSignDialog';
import FileBrowserPopup, { FileBrowserPopupExternal } from '../../../components/Session/FileBrowserPopup';
import { createIssueForFile } from '../../../util/api/issue';
import FileSignDialog from './FileApprovalSignDialog';

const ChecklistApproval = () => {
  const dispatch = useDispatch()
  const { sessionID } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const [session, setSession] = useState(null)
  const [rejectDialogOpen, setRejectDialogOpen] = useState(false);
  const [loading, setLoading] = useState(true)
  const [fileSignatureOpen, setFileSignatureOpen] = useState(false);
  const [projectSignatureOpen, setProjectSignatureOpen] = useState(false);
  const [fileBrowserOpen, setFileBrowserOpen] = useState(false);
  const [fileIndex, setFileIndex] = useState(0)
  const [fileRejectDialogOpen, setFileRejectDialogOpen] = useState(false);

  const fetchSession = () => {
    getChecklistApprovalSession(sessionID, searchParams.get("key")).then((res) => {
      setSession(res.data)
      setLoading(false)
    }).catch((e) => {
      dispatch(setError("Could not fetch session: " + e.response?.data?.detail))
    })
  }

  useEffect(() => {
    if (sessionID !== null) {
      fetchSession();
    }
  }, [sessionID])

  const handleProjectApprove = () => {
    setProjectSignatureOpen(true)
  }

  const handleFileApprove = () => {
    setFileSignatureOpen(true)
  }
  const handleReject = () => {
    setRejectDialogOpen(true)
  }

  const onRejectSubmit = (value) => {
    postIssue(sessionID, searchParams.get("key"), value).then(() => {
      fetchSession();
    })
  }

  const handleRejectDialogClose = () => {
    setRejectDialogOpen(false);
  }

  const handleFileReject = () => {
    setFileRejectDialogOpen(true)
  }

  const onFileRejectSubmit = (value) => {
    createIssueForFile(session.session_id, session?.files[fileIndex].id, value).then((_res) => {
      setFileIndex(fileIndex + 1)
      handleRejectDialogClose();
    })
  }

  const handleFileRejectDialogClose = () => {
    setFileRejectDialogOpen(false);
  }

  useEffect(() => {
    if (session?.files[fileIndex]?.approved_at) {
      setFileIndex(fileIndex + 1)
    }
  }, [session, fileIndex])

  const ActionButtons = () => {
    return (
      <Box sx={{
        position: "absolute",
        bottom: 50,
        zIndex: 1,
        width: "100vw"
      }}>
        <Grid container>
          <FileBrowserPopupExternal open={fileBrowserOpen} onClose={() => setFileBrowserOpen(false)} projectID={session?.session?.id} />
          <Grid item xs={1} md={4} lg={5}>

          </Grid>
          <Grid item xs={10} md={4} lg={2}>
            <Paper sx={{ padding: "10px", display: "flex", justifyContent: "center", gap: "100px" }}>
              <Button
                onClick={handleReject}
                startIcon={<ThumbDownIcon />}
                variant="outlined"
                sx={{ width: "120px" }}
              >
                Reject
              </Button>
              {/* <Button
                onClick={() => setFileBrowserOpen(true)}
                sx={{ width: "120px", mr: 2, ml: 2 }}>
                Show Files
              </Button> */}
              <Button
                sx={{
                  width: "120px"
                }}
                onClick={handleProjectApprove}
                endIcon={<ThumbUpIcon />}
                variant="outlined"
              >
                Approve
              </Button>
            </Paper>
          </Grid>

          <Grid item xs={5}>

          </Grid>
        </Grid>
      </Box>
    )

  }

  const FileActionButtons = () => {
    return (
      <Box sx={{
        position: "absolute",
        bottom: 50,
        zIndex: 1,
        width: "100vw"
      }}>
        <Grid container>
          <Grid item xs={1} md={4} lg={5}>

          </Grid>
          <Grid item xs={10} md={4} lg={2}>
            <Paper sx={{ padding: "10px", display: "flex", justifyContent: "center", gap: "100px" }}>
              <Button
                onClick={handleFileReject}
                startIcon={<ThumbDownIcon />}
                variant="outlined"
                sx={{ width: "120px" }}
              >
                Reject
              </Button>
              {/* <Button
                onClick={() => setFileBrowserOpen(true)}
                sx={{ width: "120px", mr: 2, ml: 2 }}>
                Show Files
              </Button> */}
              <Button
                sx={{
                  width: "120px"
                }}
                onClick={handleFileApprove}
                endIcon={<ThumbUpIcon />}
                variant="outlined"
              >
                Approve
              </Button>
            </Paper>
          </Grid>

          <Grid item xs={5}>

          </Grid>
        </Grid>
      </Box>
    )

  }

  const open = (session?.session?.checklist_state === "LOCKED" && session?.issues?.length === 0)
  console.log(session)
  return (
    loading ? <Loading /> :
      <div>

        <RejectionPopup
          open={rejectDialogOpen}
          onClose={handleRejectDialogClose}
          onSubmit={onRejectSubmit}

        />

        <RejectionPopup
          open={fileRejectDialogOpen}
          onClose={handleFileRejectDialogClose}
          onSubmit={onFileRejectSubmit}

        />
        <ChecklistSignDialog
          onClose={() => { setProjectSignatureOpen(false) }}
          open={projectSignatureOpen}
          title={session?.session?.title}
          onSignOff={() => { setProjectSignatureOpen(false); fetchSession(); }}
          signKey={searchParams.get("key")}
          sessionID={sessionID}
        />
        <FileSignDialog
          fileID={session?.files[fileIndex]?.id}
          onClose={() => { setFileSignatureOpen(false) }}
          open={fileSignatureOpen}
          title={session?.files[fileIndex]?.title}
          onSignOff={() => { setFileSignatureOpen(false); setFileIndex(fileIndex + 1) }}
          signKey={searchParams.get("key")}
          sessionID={sessionID}
        />
        {open && fileIndex >= session?.files.length && < ActionButtons />}
        {open && fileIndex < session?.files.length && <FileActionButtons />}

        <Grid>
          {!open && <Done />}
          {open && fileIndex < session?.files.length && <PDFViewer
            filename={session?.files[fileIndex] ? `/data/${session?.files[fileIndex]?.file_location}` : ""}
            title={session?.files[fileIndex]?.title}
          />}

          {open && !fileBrowserOpen && fileIndex >= session?.files.length && <PDFViewer
            title={session?.session?.title + " Test Plan"}
            filename={`/api/approval/checklist/testplan/${searchParams.get("key")}`} />}
        </Grid>
      </div>
  )
}

export default ChecklistApproval;