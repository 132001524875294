import { useState } from 'react';
import { useDispatch } from 'react-redux';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';


import { logIn, setToken } from '../actions/tokenActions'
import VmatchLogo from '../static/images/logo_green.png';
import companyLogo from "../static/images/company_logo.png";
import { createMFASession, logInMFA } from '../util/api';
import { setError } from '../actions/notificationActions';
import Loading from './Loading';
import store from '../Store';



const LoginForm = () => {
  const dispatch = useDispatch()

  const [MFASessionID, setMFASessionID] = useState(undefined)
  const [username, setUsername] = useState("")
  const [password, setPassword] = useState("")
  const [loading, setLoading] = useState(false)

  const handleSubmitMFA = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const code = data.get('security_code');
    setLoading(true)

    logInMFA(username, password, MFASessionID, code.toString()).then((res) => {
      const token = res.data;
      store().persistor.purge();
      dispatch(setToken(token))
      window.location.reload();
      window.location.href = "/"
    })
      .catch((e) => {
        setLoading(false)
        dispatch(setError(e.response?.data?.detail))
        console.log(e);
        console.error("Error in login");
      });
  };

  const handleInitialLogin = (event) => {
    setLoading(true)
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const username = data.get('username');
    const password = data.get('password');

    createMFASession(username, password).then((response) => {
      setMFASessionID(response.data.id)
      setUsername(username);
      setPassword(password);
      setLoading(false);
    }).catch((e) => {
      setLoading(false)
      dispatch(setError(e.response?.data?.detail))
    })
  }

  const resendCode = () => {
    setLoading(true)
    createMFASession(username, password).then((response) => {
      setMFASessionID(response.data.id)
      setUsername(username);
      setPassword(password);
      setLoading(false);
    }).catch((e) => {
      setLoading(false)
      dispatch(setError(e.response?.data?.detail))
    })
  }

  const switchAccount = () => {
    setMFASessionID(undefined);
    setUsername("")
    setPassword("")
  }


  const InitialLoginForm = () => {
    return <Box component="form" onSubmit={handleInitialLogin} noValidate
      sx={{
        mt: 1,
        width: "500px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column"
      }}>

      <TextField
        margin="normal"
        required
        fullWidth
        id="username"
        label="Username"
        name="username"
        autoComplete="username"
        autoFocus
        InputLabelProps={{
          style: {
            color: "#e2e8f0",
            borderColor: "#e2e8f0"
          }
        }}
      />
      <TextField
        color="primary"
        margin="normal"
        required
        fullWidth
        name="password"
        label="Password"
        type="password"
        id="password"
        autoComplete="current-password"
        InputLabelProps={{
          style: {
            color: "#e2e8f0",
            borderColor: "#e2e8f0"
          }
        }}
      />
      <Button
        type="submit"
        fullWidth
        color="primary"
        variant="contained"
        sx={{ mt: 3, mb: 2 }}
      >
        Sign In
      </Button>

      <Box sx={{ display: "flex", width: "100%", mb: 20 }}>
        <a href="/register" style={{ width: "50%", textAlign: "left", textDecoration: "underline", color: "white" }}>Register</a>
        <a href="/forgotPassword" style={{ width: "50%", textAlign: "right", textDecoration: "underline", color: "white" }}>Forgot Password?</a>
      </Box>

    </Box >
  }

  const MFAForm = () => {
    return <Box component="form" onSubmit={handleSubmitMFA} noValidate
      sx={{
        mt: 1,
        width: "500px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column"
      }}>
      <Typography>A security code has been sent to your email.</Typography>
      <TextField
        margin="normal"
        required
        fullWidth
        id="security_code"
        label="Security Code"
        name="security_code"
        autoFocus
        InputLabelProps={{
          style: {
            color: "#e2e8f0",
            borderColor: "#e2e8f0"
          }
        }}
      />
      <Box sx={{ display: "flex", width: "100%" }}>
        <Button
          color="primary"
          sx={{ ml: 0, mr: "auto", float: "left" }}
          onClick={() => { switchAccount() }}>
          Switch Account
        </Button>

        <Button
          color="primary"
          sx={{ ml: "auto", mr: 0 }}
          onClick={() => { resendCode() }}>
          Resend Code
        </Button>
      </Box>

      <Button
        type="submit"
        fullWidth
        color="primary"
        variant="contained"
        sx={{ mt: 3, mb: 2 }}
      >
        Sign In
      </Button>
    </Box>
  }

  return (
    <div>
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          alignContent: 'center'
        }}
      >
        <Typography style={{ marginBottom: 30 }}>
          <img src={companyLogo} style={{ display: "inline-block" }} width="100%" alt="Company Logo" />
        </Typography>
        <Typography fontWeight={100} color="text.primary" variant="h5" style={{ marginBottom: 30 }}>
          <b>Hi there!</b> Let’s get started with your project inspections
        </Typography>

        {loading && <Loading sx={{ height: "100%", m: 2, mb: 10 }} />}
        {!loading && MFASessionID === undefined && <InitialLoginForm />}
        {!loading && MFASessionID !== undefined && <MFAForm />}

        <Typography variant="h6" fontWeight={400} >
          <p>
            Connected by
            <img
              height="50px"
              // style={{display: "inline", marginBottom: "-0.25em", marginLeft: 2} }
              style={{ display: "inline", marginBottom: "-25px", marginLeft: "0.5em", cursor: "pointer" }}
              src={VmatchLogo}
              onClick={() => { window.open("https://vmatch.nl", "_blank") }}
            />
          </p>
        </Typography>

      </Box>
    </div>
  );
}

export default LoginForm;
