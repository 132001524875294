import "./checklistStyle.css"

import {
  Dialog,
  Box,
  CircularProgress,
  CardContent,
  CardHeader,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Checkbox,
  IconButton,
  DialogContent,
  Divider,
  CardActions,
  Button,
} from "@mui/material"

import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@material-ui/icons/Add';

import { useEffect, useState } from "react";
import { getChecklistOverview } from "../../util/api";

import {
  addChecklistsToProject
} from "../../util/api/session";

import { useDispatch } from "react-redux";
import { setError } from "../../actions/notificationActions";

const AddChecklistDialog = ({ projectID, open, onClose }) => {

  const [checked, setChecked] = useState([])
  const [loading, setLoading] = useState(true);
  const [availableChecklists, setAvailableChecklists] = useState([])

  const dispatch = useDispatch()

  const fetchChecklists = () => {
    getChecklistOverview()
      .then((result) => {
        setAvailableChecklists(result.data)
        setLoading(false)
      })
  }

  useEffect(() => {
    if (loading && open) {
      setChecked([])
      fetchChecklists();
    }
  }, [open])

  const handleClose = () => {
    onClose();
    setAvailableChecklists([])
    setLoading(true)
  }

  const handleToggleChecklist = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const submitSelection = () => {
    addChecklistsToProject(projectID, checked).then(() => {
      handleClose();
    }).catch((e) => {
      dispatch(setError(e.response?.data?.detail))
    })

  }

  const Loading = () => {
    return <Box sx={{
      width: "100%",
      justifyContent: "center",
      alignItems: "center",
      display: "flex",
      height: "100%"
    }}>
      <CircularProgress />
    </Box>
  }

  const ChecklistEntry = ({ checklist }) => {
    return (
      <div>
        <ListItem key={`${checklist?.id}`}>
          <ListItemText
            id={checklist?.id}
            primary={checklist?.title}
            secondary={checklist?.description}
          />
          <ListItemSecondaryAction>
            <Checkbox
              classes={{ root: 'overview-checkbox-root' }}
              edge="end"
              onChange={handleToggleChecklist(checklist?.id)}
              checked={checked.indexOf(checklist?.id) !== -1}
              inputProps={{ 'aria-labelledby': checklist?.id }}
            />
          </ListItemSecondaryAction>

        </ListItem>

        <Divider light />
      </div>
    )
  }

  const CloseButton = () => {
    return (
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8
        }}
      >
        <CloseIcon />
      </IconButton>)
  }

  return <Dialog
    open={open}
    onClose={handleClose}
    sx={{ zIndex: 199999999 }}
    fullWidth
    maxWidth={"md"}
    PaperProps={{
      elevation: 0,
      variant: "outlined",
      justifyContent: "center",
      textAlign: "center",
      alignItems: "center",
    }}
  >

    <DialogContent>
      <CloseButton />
      <CardHeader
        title="Checklist Library"
      />
      <CardContent>
        <List sx={{ maxHeight: "50vh", overflow: "auto" }}>
          {availableChecklists.map((checklist) => {
            return (<ChecklistEntry checklist={checklist} />)
          })}
        </List>
        {loading && <Loading />}

      </CardContent>
      <CardActions>
        <Button
          sx={{ marginTop: 0, mr: 2 }}
          disabled={checked.length === 0}
          color="primary"
          startIcon={<AddIcon />}
          onClick={() => submitSelection()}>
          Add Checklists
        </Button>
      </CardActions>
    </DialogContent>

  </Dialog>
}

export default AddChecklistDialog;