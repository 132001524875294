import { Grid, Box, CircularProgress } from "@material-ui/core";


const Loading = ({ sx = {} }) => {
  return (
    <Box display="flex"
      justifyContent="center"
      alignItems="center" sx={{ paddingTop: 2, height: "100vh", color: "red", ...sx }}>
      <CircularProgress style={{ color: "#83D100" }} size="100px" />
    </Box >
  )
}

export default Loading;