import { useState } from 'react';

import {
  Menu,
  MenuItem,
  ListItemIcon,
  Link,
  Card,
  CardHeader,
  CardContent,
  IconButton,
  CardActions,
  LinearProgress,
  Typography,
  Tooltip,
  Chip,
  Badge
} from '@mui/material';


import LoginIcon from '@mui/icons-material/Login';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import DeleteIcon from '@mui/icons-material/Delete';
import ArchiveIcon from '@mui/icons-material/Archive';
import ListAltIcon from '@mui/icons-material/ListAlt';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import AssignmentLateIcon from '@mui/icons-material/AssignmentLate';

import { getSessionPDF, getSessionItpPDF } from '../../util/api';


const parseProjectStatus = (status) => {
  switch (status) {
    case "TESTPLAN_UNAVAILABLE":
      return { progressColor: "error", actionText: "You need to select a testplan" }
    case "TESTPLAN_UNAPPROVED":
      return { progressColor: "error", actionText: "You need to request approval for your testplan" }
    case "TESTPLAN_AWAITING_APPROVAL":
      return { progressColor: "warning", actionText: "Your testplan is awaiting approval" }

    case "UNAPPROVED_FILES":
      return { progressColor: "warning", actionText: "There are files awaiting approval" }

    case "UNRESOLVED_ISSUES":
      return { progressColor: "error", actionText: "There are issues that need to be resolved" }

    case "UNFINISHED_TESTPLANS":
      return { progressColor: "error", actionText: "There are unfinished testplans" }

    case "UNAPPROVED_STEPS":
      return { progressColor: "warning", actionText: "There are unapproved steps" }

    case "READY_FOR_FINAL_APPROVAL":
      return { progressColor: "info", actionText: "Submit for final approval" }

    case "AWAITING_FINAL_APPROVAL":
      return { progressColor: "warning", actionText: "The project is awaiting final approval" }

    case "DONE":
      return { progressColor: "success", actionText: "Download MRB" }

  }
  return { progressColor: "error", actionText: "No status available" }
}

const parseHealthCheckStatus = (status) => {
  switch (status) {
    case "TESTPLAN_UNAVAILABLE":
      return { progressColor: "error", actionText: "You need to select a testplan" }
    case "TESTPLAN_UNAPPROVED":
      return { progressColor: "error", actionText: "You need to begin your testplan" }
    case "TESTPLAN_AWAITING_APPROVAL":
      return { progressColor: "warning", actionText: "Your testplan is awaiting approval" }

    case "UNAPPROVED_FILES":
      return { progressColor: "warning", actionText: "There are files awaiting approval" }

    case "UNRESOLVED_ISSUES":
      return { progressColor: "error", actionText: "There are issues that need to be resolved" }

    case "UNFINISHED_TESTPLANS":
      return { progressColor: "error", actionText: "There are unfinished testplans" }

    case "UNAPPROVED_STEPS":
      return { progressColor: "warning", actionText: "There are unapproved steps" }

    case "READY_FOR_FINAL_APPROVAL":
      return { progressColor: "info", actionText: "Submit for final approval" }

    case "AWAITING_FINAL_APPROVAL":
      return { progressColor: "warning", actionText: "The project is awaiting final approval" }

    case "DONE":
      return { progressColor: "success", actionText: "Download MRB" }

  }
  return { progressColor: "error", actionText: "No status available" }
}

const ProjectTile = ({ project, onDelete, onClientClick, onProjectSubmitClick }) => {

  const [anchorEl, setAnchorEl] = useState(null);
  const [loading, setLoading] = useState(false);

  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleIssueClick = (e) => {
    window.location.href = `/projectSettings/${project?.id}/punchlist`
  }

  const clickHandlers = {
    TESTPLAN_UNAVAILABLE: () => { window.location.href = `/projectSettings/${project?.id}/testplans` },
    TESTPLAN_UNAPPROVED: () => { window.location.href = `/projectSettings/${project?.id}/testplans` },
    TESTPLAN_AWAITING_APPROVAL: () => { window.location.href = `/projectSettings/${project?.id}/testplans` },
    UNAPPROVED_FILES: () => { window.location.href = `/projectSettings/${project?.id}/approvals` },
    UNAPPROVED_STEPS: () => { window.location.href = `/session/${project?.id}` },
    UNRESOLVED_ISSUES: () => { window.location.href = `/projectSettings/${project?.id}/punchlist` },
    UNFINISHED_TESTPLANS: () => { window.location.href = `/session/${project?.id}` },
    READY_FOR_FINAL_APPROVAL: onProjectSubmitClick,
    AWAITING_FINAL_APPROVAL: () => { window.location.href = `/projectSettings/${project?.id}/approvals` },
    DONE: () => { downloadMRB() }
  }

  const downloadMRB = () => {
    setLoading(true)
    getSessionPDF(project?.id)
      .then(() => {
        setLoading(false)
      })
  }

  const { progressColor, actionText } = project?.is_health_check_project ? parseHealthCheckStatus(project?.status) : parseProjectStatus(project?.status)

  const ExtraMenu = () => {
    return (
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem onClick={() => { window.location.href = `/projectSettings/${project.id}/files` }}>
          <ListItemIcon>
            <EditRoundedIcon fontSize="small" />
          </ListItemIcon>
          Edit
        </MenuItem>
        <MenuItem onClick={() => onDelete(project.id)}>
          <ListItemIcon>
            <DeleteIcon fontSize="small" />
          </ListItemIcon>
          Delete
        </MenuItem>
      </Menu>
    )
  }

  return (
    <Card sx={{
      marginBottom: 0,
      width: "100%",
      borderRadius: 0,
      boxShadow: "none",
      display: "flex",
      flexDirection: "column",
      background: "none",
      borderBottom: "1px solid",
      borderColor: "#212529",
      ":last-child": {
        border: "none"
      }

    }}>
      <CardHeader
        action={
          <Tooltip title="Project Status">
            <Chip clickable color={progressColor} sx={{ ml: 2, }} label={actionText} onClick={clickHandlers[project?.status]} />
          </Tooltip>
        }
        title={
          <div>
            <Link
              href={`/projectSettings/${project.id}/testplans`}
              underline="none">
              {project?.machine_model + " " + project?.machine_number}
            </Link>

          </div>
        }
        sx={{ pl: 3, pt: 2 }}
        subheader={project?.service_engineer ? project?.service_engineer : "No service engineer available"}
        subheaderTypographyProps={{
          color: "#dddddd",
          variant: "body2",
          fontSize: "14px",
          flexGrow: "1",
          pb: 1
        }}
      />
      <CardContent sx={{ pl: 2, pt: 0, pb: 0, ":last-child": { pb: 1 } }}>
        <CardActions sx={{ pl: 0, pt: 0, pb: 0 }} >

          <Tooltip title="Join Project">
            <IconButton color="primary"
              onClick={() => {
                window.location.href = `/session/${project?.id}`;
              }}
              disabled={project?.checklist_state !== "APPROVED"}
            >
              <LoginIcon />
            </IconButton>
          </Tooltip>

          <Tooltip title="MRB Download">
            <IconButton
              color="primary"
              // disabled={project?.status !== "DONE"}
              onClick={downloadMRB}>
              <ArchiveIcon />
            </IconButton>
          </Tooltip>

          <Tooltip title="ITP Download">
            <IconButton
              color="primary"
              onClick={() => {
                setLoading(true)
                getSessionItpPDF(project?.id)
                  .then(() => {
                    setLoading(false)
                  })
              }}
            >
              <ListAltIcon />
            </IconButton>
          </Tooltip>

          <Tooltip title="Pending Approvals">
            <IconButton
              color="primary"
              onClick={() => window.location.href = `/projectSettings/${project?.id}/approvals`}
            >
              <Badge color="error" badgeContent={project?.pending_approval_count} >
                <HowToRegIcon color="white" />
              </Badge>
            </IconButton>

          </Tooltip>
          <Tooltip title="Unresolved Issues">
            <IconButton color="primary" onClick={handleIssueClick}>
              <Badge color="error" badgeContent={project?.issues?.filter((issue) => {
                return issue.resolver === null
              }).length}>
                <AssignmentLateIcon />
              </Badge>
            </IconButton>
          </Tooltip>

          <Chip clickable onClick={onClientClick} sx={{ ml: 2, cursor: "pointer" }} label={project?.client_name} />

          <Typography sx={{ ml: "auto", mr: 0, color: "#dddddd" }} variant="caption">
            Updated on {new Date(Date.parse(project?.updated_at)).toDateString()}
          </Typography>

        </CardActions >

      </CardContent>

      {loading && <LinearProgress />}
      <ExtraMenu />
    </Card >
  )
}

export default ProjectTile;
